/* General header styling */
.header {
  box-sizing: border-box;
  display: flex;
  height: 64px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #272727;
}

/* Logo styling */
.logo {
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
}

/* Text styling for large screens (hidden on small screens) */
.text-large-screen {
  display: none;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
}

.text-large-screen p {
  color: white;
  margin: 0;
}

/* Text styling for small screens (hidden on large screens) */
.text-small-screen {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.text-small-screen p {
  color: white;
  margin: 0;
}

/* Media query for larger screens (starting from 768px and above) */
@media (min-width: 901px) {
  .text-large-screen {
    display: flex;
  }

  .text-small-screen {
    display: none;
  }
}
