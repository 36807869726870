
.curosalBackground {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px; 
    border-radius: 8px;
    box-shadow: var(--Tokens-Elevation-01, 0px 1px 3px rgba(0,0,0,0.2));
}