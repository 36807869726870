.titleText {
    font-feature-settings: 'liga' off, 'clig' off !important;
    font-family: 'Inter', sans-serif !important;
    font-size: clamp(14px, 5vw, 18px) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }
.cardStyle{
  gap: 8px;
  width: 100%;
  border-radius: 16px;
}
.specialProjectDocument {
  display: flex;
  padding: var(--Spacing-spacing-16, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-spacing-8, 8px);
  border-radius: var(--Corner-radius-corner-radius-small, 8px);
  border: 1px solid var(--Tokens-Border-Default, #D7E5F8);
}
.textStyle {
  font-family: var(--Typography-Font-Family-Body, Inter);
  font-size: var(--Font-Size-Small, 14px);
  font-style: normal;
  line-height: var(--Line-Height-Small, 20px);
}
.ButtonStyle {
  display: flex;
  height: var(--Spacing-spacing-32, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Corner-radius-effect-corner-3, 24px);
  padding: 4px 10px;
  cursor: pointer;
}
.ButtonBotttomBoxStyle {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 24px 8px rgba(0, 92, 188, 0.12);
}
.ButtonBox {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 0 0;
  gap: 10px;
  padding: 10px;
}