.DeniedTitle{
    font-family: var(--Typography-Font-Family-Heading, Inter) !important;
    font-size: var(--Font-Size-H6, 18px) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: var(--Line-Height-H6, 28px) !important;
  }

  .text-style {
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: var(--Font-Size-XSmall, 12px) !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: var(--Line-Height-XSmall, 18px) !important;
    text-transform: uppercase;
    padding: 16px;
  }