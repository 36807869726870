.responsive-main {
  flex: 1;
  margin-top: 6rem;
}

/* For screens above 1200px */
@media (min-width: 1200px) {
  .responsive-main {
    margin-top: 8.3rem;
  }
}

/* For screens below 938px */
@media (max-width: 937px) {
  .responsive-main {
    margin-top: 3rem;
  }
}
