.DetailCard {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-spacing-16, 12px);
}

.DetailContents {
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: var(--Font-Size-Body, 16px) !important;
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-Height-Body, 22px) !important;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%  !important;
}